<template>
	<div>
	<div
			class="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12"
			style="
				background-image: url('https://gordon.armymwr.com/application/files/4915/7314/2606/SpecialEvents_XmasFest_EH.jpg');
                background-position:center;
                background-size:cover;
			"
		>

    <div class="flip-card">
  <div class="flip-card-inner">
    <div class="flip-card-front"></div>




    	
			<div class="relative py-3 sm:max-w-xl sm:mx-auto">
				<div
					class="absolute inset-0 bg-gradient-to-r from-green-600 to-green-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"
				></div>
				<div
					class="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20"
				>
					<div class="max-w-md mx-auto">
						<div class="flex justify-center items-center">
							<img src="https://icons.iconarchive.com/icons/icons8/christmas-flat-color/256/christmas-tree-icon.png" class="h-32" />
						</div>
						<div class="divide-y divide-gray-200">
							<div
								class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7"
							>
								<p class="text-5xl font-bold">Frohe Weihnachten</p>
								<ul class="list-disc space-y-2">
									<li class="flex items-start">
										<span class="h-6 flex items-center sm:h-7">
											<svg
												class="flex-shrink-0 h-5 w-5 text-cyan-500"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fill-rule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
													clip-rule="evenodd"
												/>
											</svg>
										</span>
										<p class="ml-2">
											Customizing your
											<code class="text-sm font-bold text-gray-900"
												>tailwind.config.js</code
											>
											file
										</p>
									</li>
									<li class="flex items-start">
										<span class="h-6 flex items-center sm:h-7">
											<svg
												class="flex-shrink-0 h-5 w-5 text-cyan-500"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fill-rule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
													clip-rule="evenodd"
												/>
											</svg>
										</span>
										<p class="ml-2">
											Extracting classes with
											<code class="text-sm font-bold text-gray-900"
												>@apply</code
											>
										</p>
									</li>
									<li class="flex items-start">
										<span class="h-6 flex items-center sm:h-7">
											<svg
												class="flex-shrink-0 h-5 w-5 text-cyan-500"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fill-rule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
													clip-rule="evenodd"
												/>
											</svg>
										</span>
										<p class="ml-2">Code completion with instant preview</p>
									</li>
								</ul>
								<p>
									Perfect for learning how the framework works, prototyping a
									new idea, or creating a demo to share online.
								</p>
							</div>
							<div
								class="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7"
							>
								<p>Want to dig deeper into Tailwind?</p>
								<p>
									<a
										href="https://tailwindcss.com/docs"
										class="text-cyan-600 hover:text-cyan-700"
									>
										Read the docs &rarr;
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>



    </div>
    <div class="flip-card-back">
      <h1>John Doe</h1> 
      <p>Architect & Engineer</p> 
      <p>We love that guy</p>
    </div>
  </div>
</div>

		</div>



	</div>
</template>

<script>
export default {};
</script>

<style>
.flip-card {
	background-color: transparent;
	perspective: 1000px;
}

.flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 0.6s;
	transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
	transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.flip-card-front {
}

.flip-card-back {
	color: white;
	transform: rotateY(180deg);
}
</style>