<template>
  <div
    class="flex flex-col justify-center w-full min-h-screen py-6 bg-gray-100 sm:py-12"
  >
    <div class="flex items-center justify-center w-full">
      <div class="flip-card">
        <div
          class="absolute inset-0 transform skew-y-0 shadow-lg bg-gradient-to-r from-green-900 to-green-700 sm:-rotate-6 sm:rounded-3xl"
        ></div>
        <div class="relative py-3 flip-card-inner sm:max-w-3xl sm:mx-auto">
          <div
            class="px-4 py-10 bg-white shadow-lg flip-card-front rounded-3xl"
          >
            <div class="flex flex-col items-center justify-center">
              <div
                class="flex items-center justify-between w-3/5 pt-16 pb-8 mx-auto"
              >
                <div>
                  <img src="../assets/blatt1.png" />
                </div>
                <div class="ml-8">
                  <img class="" src="../assets/blatt1-1.png" />
                </div>
              </div>
              <div class="px-8 christmas-font text-8xl">Frohe Weihnachten!</div>
            </div>
          </div>

          <div class="py-4 bg-white shadow-lg flip-card-back sm:rounded-3xl">
            <div>
              <div class="px-4 text-left text-green-800">
                Lieber Papa,
                <br />
                <br />
                das ist mal eine ganz andere Weihnachtskarte als sonst... ein
                bisschen umweltfreundlicher (wer weiß?), aber wenn du möchtest,
                druck ich sie dir auch gern noch aus. :)<br />
                Das war schon irgendwie ein krasses Jahr, oder? Im Januar haben
                sich noch alle aufgeregt, dass es jetzt Kassenbonpflicht gibt,
                erinnerst du dich? So 'n blödes Virus verschiebt dann doch
                wieder ganz schön die Perspektive.<br /><br />
                Auch wenn wir das dieses Jahr bisher ganz gut gemanaged haben,
                hoffe ich, dass wir uns im kommenden Jahr einfach wieder
                »normaler« sehen können; Quatsch machen, laufen gehen, Kaffee
                trinken, stundenlang philosophieren und streiten, irgendwo
                hinfahren und wandern und mit Lotti rumblödeln. Auf jeden Fall
                hat mir dieses Jahr gezeigt, dass man seine Prioritäten und
                Perspektiven doch ab und zu evaluieren sollte.<br />
                Ok, die Karte wurde jetzt wirklich komplett anders als geplant,
                daher folgt hier der Standardtext, der aber dennoch ernst
                gemeint ist:<br />
                <br />
                Ich wünsche dir und Kerstin frohe Weihnachten und einen guten
                Rutsch ins neue Jahr, auf dass es dreimal so gut werden wird wie
                2019(!)!<br />
                <br />
                Ich hab dich lieb.
                <div class="w-1/4 text-right">- Tochter</div>
              </div>
              <div
                class="flex items-center justify-end w-1/6 pb-8 ml-auto mr-8 -mt-20"
              >
                <div>
                  <img src="../assets/blatt2.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {};
</script>

<style>
.christmas-font {
  font-family: "Amatic SC";
}

.flip-card {
  background-color: transparent;
  width: 800px;
  height: 600px;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: dodgerblue;
  color: white;
  transform: rotateY(180deg);
}
</style>